
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import api from '../services/api'
// import token_service from '../services/token_service'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {},
        days_filter: {},
        customers: [],
        active_customers: [],
        status_shown: ["activo", "recordar", "expirado"],
        status_counts: {},
        all_channels: [],
        include_auto_charge: true,
        notifications: []
    },
    getters: {
        user: state => { return state.user },
        customers: state => { return state.customers },
        status_shown: state => { return state.status_shown},
        all_channels: state => { return state.all_channels },
        include_auto_charge: state => { return state.include_auto_charge },
        days_filter: state => { return state.days_filter },
        notifications: state => { return state.notifications },
        status_counts: state => { return state.status_counts}
    },
    actions: {
        get_status_counts ( { commit, state} ) {
			var counts = { todos: 0, recordar: 0, activo: 0, expirado: 0 };
			state.customers.flatMap((customer) =>                
				customer.subscriptions.forEach((subscription) => {
					counts["todos"] += 1;
					if (counts[subscription.status]) {
						counts[subscription.status] += 1;
					} else {
						counts[subscription.status] = 1;
					}
				})
			);
			commit('set_status_counts', counts)
            return counts
        },
        async change_days_filter( { commit, dispatch}, days_filter) {
            commit('set_days_filter', days_filter)
            await dispatch('get_customers', days_filter)
            dispatch('get_status_counts')

        },
        toggle_auto_charge( { commit }) {
            commit('set_include_auto_charge')
        },
        logout({ commit }) {
            api.logout().then(() => {
                commit('set_user', {})
                commit('set_notifications', [])
                console.log('log out from global action')
            })
        },
        login({ commit }, user_data) {
            return api.login(user_data).then((response) => {
                if (response.status == 200) {
                    // console.log('new login, access token ' + response.data.access_token.substring(response.data.access_token.length - 5))
                    commit('set_user', response.data)
                }
            })
        },
        update_access_token({ commit }, access_token) {
            commit('set_access_token', access_token)
        },
        get_customers( { commit }, days_filter ) {
            return api.get_customers(days_filter).then((response) => {
                const customers = response.data
                commit('set_customers', customers)
            })
        },
        get_inactive_customers () {
            return api.get_inactive_customers()
        },
        set_status_shown({ commit }, status) {
            commit('set_status_shown', status)
        },
        get_all_channels ( { commit }) {
            return api.get_channels().then((response) => {
                const all_channels = response.data
                commit('set_all_channels', all_channels)
            })
        },
        get_notifications({ commit }, userId) {
            return api.get_notifications(userId).then((response) => {
                const notifications = response.data
                commit('set_notifications', notifications)
            })
        }
    },
    mutations: {
        set_status_counts(state, status_counts) {
            state.status_counts = status_counts
        },
        set_days_filter(state, new_days) {
            state.days_filter = new_days
        },
        set_include_auto_charge(state) {
            state.include_auto_charge = !state.include_auto_charge
        },
        set_user(state, user) {
            state.user = user
        },
        set_access_token(state, access_token) {
            state.user.access_token = access_token
        },
        set_customers(state, customers) {
            state.customers = customers
        },
        set_notifications(state, notifications) {
            state.notifications = notifications
        },
        set_status_shown(state, status_shown) {
            state.status_shown = status_shown
        },
        set_all_channels(state, all_channels) {
            state.all_channels = all_channels
        }
    },
    plugins: [
        createPersistedState({ })
    ]
})
