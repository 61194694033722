var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"items":_vm.payment_records,"headers":_vm.columns},scopedSlots:_vm._u([{key:"item.credits",fn:function(ref){
var item = ref.item;
return _vm._l((item.payment_details),function(payment,index){return _c('div',{key:index,staticClass:"d-flex justify-left"},[_vm._v(" "+_vm._s(payment.credits)+" ")])})}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.payment_date))+" ")]}},{key:"item.payment_details",fn:function(ref){
var item = ref.item;
return _vm._l((item.payment_details),function(payment,index){return _c('div',{key:index,staticClass:"d-flex justify-left"},[_vm._v(" "+_vm._s(payment.channel_name)+" ")])})}},{key:"item.undo_payment",fn:function(ref){
var item = ref.item;
return [(item.id == Math.max.apply(null, _vm.payment_records.map((function (payment) { return payment.id; }))))?_c('v-btn',{attrs:{"fab":"","small":"","color":"info","disabled":item.reversed || item.payment_type === 'reversion' || item.payment_type === 'nuevo cliente'},on:{"click":function($event){return _vm.undo_payment(item)}}},[_c('v-icon',[_vm._v("mdi-undo")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }