<template>
	<v-container fluid>
		<v-container v-if="aggregated_data" fluid>
			<v-container>
				<v-row align="center">
					<v-col cols="1"><v-text-field field v-model="year" type="number" label="Año" /></v-col>
					<v-col cols="1"><v-text-field field v-model="month" type="number" label="Mes" /></v-col>
					<v-col cols="2"><v-btn @click="get_payments_aggregations()" color="primary" elevation="2" small>Ir</v-btn></v-col>
				</v-row>
			</v-container>

			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th v-for="header in headers" :key="header" :class="header.style">
								<p v-if="header == 'Monto Total' || header == 'Créditos Total'" class="total">{{ header }}</p>
								<p v-else-if="header == 'Día'" class="centered">{{ header }}</p>
								<p v-else>{{ header }}</p>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="item in aggregated_data.data" :key="item.payment_date">
							<td>
								<v-btn color="#F9F7F7" elevation="2" class="font-weight-bold" rounded small @click="get_payment_details(item.payment_day)">{{
									item.payment_day
								}}</v-btn>
							</td>
							<td v-for="(item, index) in item.methods" :key="index">
								<span v-if="index === 'Total_Metodos'" class="total">${{ item }}</span>
								<span v-else>{{ item }}</span>
							</td>
							<td v-for="(credits, key) in item.credits" :key="key">
								<v-btn
									v-if="key != 'Total_Creditos'"
									color="#F9F7F7"
									class="font-weight-bold"
									rounded
									small
									@click="get_payment_details(item.payment_day, key)"
									>{{ credits }}</v-btn
								>
								<span v-else class="total">{{ credits }}</span>
							</td>
						</tr>
					</tbody>

					<tfoot v-if="aggregated_data.amount_totals">
						<tr class="footer">
							<td></td>
							<td>${{ aggregated_data.amount_totals["BANCO"] }}</td>
							<td>${{ aggregated_data.amount_totals["ADMINISTRATIVO"] }}</td>
							<td>${{ aggregated_data.amount_totals["EFECTIVO"] }}</td>
							<td>${{ aggregated_data.amount_totals["CC/CARGO AUT"] }}</td>
							<td>${{ aggregated_data.amount_totals["PAYPAL"] }}</td>
							<td>${{ aggregated_data.amount_totals["COMPRA CLICK"] }}</td>
							<td>${{ aggregated_data.amount_totals["SINPE"] }}</td>
							<td>${{ aggregated_data.amount_totals["REFERIDOS"] }}</td>
							<td>${{ aggregated_data.amount_totals["TOTAL"] }}</td>
							<td>{{ aggregated_data.credits_totals["Pop"] }}</td>
							<td>{{ aggregated_data.credits_totals["Rocket"] }}</td>
							<td>{{ aggregated_data.credits_totals["Sky Soccer"] }}</td>
							<td>{{ aggregated_data.credits_totals["Stream TV"] }}</td>
							<td>{{ aggregated_data.credits_totals["Total"] }}</td>
						</tr>
					</tfoot>
				</template>
			</v-simple-table>
		</v-container>

		<v-dialog v-model="payment_details_dialog" @input="close">
			<v-card>
				<v-card-title>
					<v-row>Pagos</v-row>
				</v-card-title>
				<v-card-text>
					<v-data-table :items="Object.values(payment_details)" :headers="payment_details_headers" disable-pagination hide-default-footer :loading="loading">
						<template v-slot:item.payment_details="{ item }">
							<v-row no-gutters v-for="(payment, index) in item.payment_details" :key="index" class="d-flex justify-left">
								<v-col>{{ payment.channel_name }}</v-col>
								<v-col>{{ payment.credits }}</v-col>
							</v-row>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import api from "../services/api.js";

export default {
	components: {},
	data() {
		return {
			loading: true,
			payment_details_dialog: false,
			payment_details: {},
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			panel_data: {
				panels: [],
			},
			itemsPerPage: -1,
			aggregated_data: [],
			headers: [
				"Día",
				"BANCO",
				"ADMINISTRATIVO",
				"EFECTIVO",
				"CC/CARGO AUT",
				"PAYPAL",
				"COMPRA CLICK",
				"SINPE",
				"REFERIDOS",
				"Monto Total",
				"Pop",
				"Rocket",
				"Sky Soccer",
				"Stream TV",
				"Créditos Total",
			],
			payment_details_headers: [
				{ text: "Nombre", value: "customer.name", width: "150" },
				{ text: "Monto", value: "amount", width: "80" },
				{ text: "Tipo", value: "paid_via", width: "100" },
				{ text: "Comprobante", value: "voucher", width: "150" },
				{ text: "Subscripción / Créditos", value: "payment_details", width: "150" },
			],
		};
	},
	computed: {
		available_credits() {
			return (this.panel_data.credits_available - this.panel_data.credits_used).toFixed(2);
		},
		notifications() {
			return this.$store.getters.notifications;
		},
	},
	methods: {
		close() {
			this.payment_details = {};
		},
		get_payment_details(day, channel_name) {
			this.payment_details_dialog = true;
			if (channel_name) {
				channel_name = channel_name.replace("_", " ");
			}
			api.get_payment_details(this.year, this.month, day, channel_name).then((response) => {
				this.loading = false;
				this.payment_details = response.data;
			});
		},
		get_style(column_name) {
			column_name = column_name.replace(" ", "_");
			return this.styles[column_name];
		},
		get_panels() {
			api.get_panels().then((response) => {
				this.panel_data = response.data;
			});
		},
		get_payments_aggregations() {
			api.get_payments_aggregations(this.year, this.month).then((response) => {
				this.aggregated_data = response.data;
			});
		},
		edit_panel(panel, index) {
			this.panel_data.panels.splice(index, 1, { ...panel, edit: true });
		},
		save_panel(panel) {
			delete panel.edit;
			api.update_panel(panel).then(() => this.get_panels());
		},
	},
	mounted() {
		this.$nextTick(function () {
			this.get_payments_aggregations();
		});
	},
};
</script>
<style scoped>
.normal {
	background-color: pink !important;
}

.centered {
	text-align: left;
}

.footer {
	font-weight: bolder;
	background-color: antiquewhite;
}
.total {
	/* background-color: blanchedalmond !important; */
	/* text-align: center; */
	color: black;
	font-weight: bolder;
	overflow-wrap: none;
}

.Sky_Soccer {
	background-color: yellow !important;
}
.Pop {
	background-color: black !important;
	color: white !important;
}
.Stream_TV {
	background-color: red !important;
	color: white !important;
}
.Onn {
	background-color: #0070c0 !important;
	color: white !important;
}

.Firestick {
	background-color: #00fdff !important;
}

.Rocket {
	background-color: #c65911 !important;
}
</style>
