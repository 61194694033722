<template>
	<v-card>
		<v-card-title>
			<v-row justify="center">Hacer pago</v-row>
		</v-card-title>
		<v-card-text>
			<v-row justify="center">
				<v-col lg="4" md="auto" sm="auto" v-for="subscription in subscriptions" :key="subscription.id">
					<v-sheet elevation="1" outlined tile>
						<ChannelsComponent :subscription="subscription" :edit="true" ref="subscription_ref" />

						<v-divider></v-divider>
					</v-sheet>
				</v-col>
			</v-row>

			<v-row justify="center">
				<v-form v-model="isFormValid">
					<v-row justify="center">
						<v-col>
							<v-select :rules="[(v) => !!v || 'Tipo de pago es requerido']" label="Tipo de pago" :items="payment_types" v-model="paid_via" />
						</v-col>
						<v-col>
							<v-text-field label="Comprobante" v-model="voucher" persistent-hint />
						</v-col>
					</v-row>

					<v-row justify="center">
						<v-col>
							<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="payment_date" label="Fecha de pago" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="payment_date" @input="menu = false" locale="es"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col>
							<v-text-field
								label="Monto"
								type="number"
								min="1"
								v-model="manual_amount"
								:hint="'$' + String(payment_amount)"
								persistent-hint
								prefix="$"
								:rules="[(v) => !!v || 'Monto es requerido']"
							/>
						</v-col>
					</v-row>
				</v-form>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-container>
				<v-row justify="center">
					<v-btn color="primary" large @click="submit_payment()" :disabled="!isFormValid">Confirmar </v-btn>
				</v-row>
			</v-container>
		</v-card-actions>
	</v-card>
</template>

<script>
import api from "../../services/api";
import ChannelsComponent from "../ChannelsComponent.vue";
import moment from "moment"

export default {
	props: ["customerId", "subscriptions"],
	components: { ChannelsComponent },
	data() {
		return {
			payment_types: ["BANCO", "EFECTIVO", "PAYPAL", "CC/CARGO AUT", "ADMINISTRATIVO", "COMPRA CLICK", "SINPE", "REFERIDOS"],
			isFormValid: false,
			credits_rules: {
				min: (value) => value > 0,
			},
			payment_info: [],
			paid_via: "",
			manual_amount: 1,
			voucher: "",
			menu: false,
			payment_date: moment().toISOString(true).split("T")[0],
		};
	},
	computed: {
		payment_amount() {
			const payment_info_credits = this.payment_info.filter((p) => {
				return p.credits != 0;
			});

			if (payment_info_credits.length > 0) {
				return this.payment_info.map((payment) => payment.payment_result).reduce((acc, amount) => acc + amount);
			}
			return 0;
		},
	},
	methods: {
		submit_payment() {
			const payment_info = {
				customerId: this.customerId,
				user: this.$store.getters.user.username,
				total_amount: this.manual_amount,
				voucher: this.voucher,
				paid_via: this.paid_via,
				payment_date: this.payment_date,
				payments: this.payment_info
					.map((p) => {
						return {
							credits: p.credits,
							sub_id: p.subscription.id,
							sub_status: p.subscription.status,
							sub_next_payment_date: p.subscription.next_payment_date,
							panel: p.panel.id,
						};
					})
					.filter((p) => p.credits != 0),
			};

			api.make_payment(payment_info).then((response) => {
				if (response.data == "OK") {
					this.$emit("saved_ok");
                    this.$store.dispatch("get_status_counts")
				}
			});
		},
	},

	mounted() {
		this.payment_info = this.$refs.subscription_ref;
	},
};
</script>

<style></style>
