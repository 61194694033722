import VueRouter from 'vue-router';
import Vue from 'vue';
import App from './App.vue'
import router from './router'
// import moment from "moment";
import vuetify from './plugins/vuetify'
import store from './store';

// if (process.env.VUE_APP_MODE == 'test') {
//     Vue.config.devtools = true
// }


require('moment/locale/es')


// Vue.use(require('vue-moment'), { moment });
Vue.use(VueRouter)
// Vue.use(Buefy)

Vue.filter('formatDate', function (date) {
    if (date) {
        const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }
        // options.timeZone = 'UTC'

        return new Date(date).toLocaleDateString('es', options)//.split(",")[0]
    }
})


Vue.filter('booleanToString', function (boolean) {
    if (boolean === true) { return 'Sí' }
    if (boolean === false) { return 'No' }
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')


