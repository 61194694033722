<template>
  <v-card>
    <v-card-title>
      <v-row justify="center">Agregar Cliente</v-row></v-card-title
    >
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6">
          <!-- <v-text-field label="Num. Cliente" v-model="customer.customer_no" /> -->
          <v-text-field label="Nombre" v-model="customer.name" />
          <v-text-field label="Email" v-model="customer.email" />
          <v-text-field label="Tel." v-model="customer.tel" />
          <v-textarea label="Nota 1" v-model="customer.nota1" />
          <v-textarea label="Nota 2" v-model="customer.nota2" />
          <v-checkbox label="Cargo automático" v-model="customer.auto_charge" />
          <v-text-field
            v-if="customer.auto_charge"
            label="Día cargo automático"
            v-model="customer.auto_charge_day"
          />
          <v-checkbox v-model="customer.referred" label="Referido" />

          <v-autocomplete
            v-if="customer.referred"
            v-model="customer.referredId"
            :items="customers"
            label="Referido por"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      customer: {},
    };
  },
  computed: {
    customers() {
      return this.$store.getters.customers.map((customer) => {
        return {
          value: customer.id,
          text: customer.name + " | " + customer.customer_no,
        };
      });
    },
  },
};
</script>

<style>
</style>