<template>
  <v-container>
    <v-container>
      <v-row justify="center"><p class="text-h5">Clientes Inactivos</p></v-row>
    </v-container>
    <!-- <v-container>
      <v-row justify="center">
        <v-col cols="12" align="center">
          <v-banner color="info" class="white--text font-weight-bold"
            >Esta lista contiene clientes con 4 meses o más de
            inactividad</v-banner
          >
        </v-col>
      </v-row>
    </v-container> -->
    <v-data-table
      class="table is-striped is-size-6"
      :headers="columns"
      :items="inactive_customers"
      :search="search"
      paginated
      items-per-page.sync="15"
      sort-by="customer_no"
      page.sync="1"
    >
      <template v-slot:item.devices="{ item }">
        <v-row
          no-gutters
          v-for="subscription in item.subscriptions"
          :key="subscription._id"
        >
          <v-col cols="5" sm="3" md="3"> {{ subscription.channel.name }}</v-col>
          <v-col cols="4" md="3" sm="4"
            >{{ subscription.days_left }} días</v-col
          >
        </v-row>
      </template>

      <template v-slot:item.active="{ item }">
        <p v-if="item.active">activo</p>
        <p v-else class="red--text text--darken-1 font-weight-bold">inactivo</p>
      </template>

      <template v-slot:item.details="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="info"
          @click="navigate(item.id)"
        >
          <v-icon dark> mdi-magnify </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      inactive_customers: [],
      columns: [
        { text: "Num. Cliente", value: "customer_no", width: "100" },
        { text: "Nombre", value: "name", searchable: true, width: "300" },
        { text: "Estado", value: "active" },
        // { text: "Email", value: "email", searchable: true },
        // { text: "Tel", value: "phone" },
        { text: "Dispositivos", value: "devices" },
        { text: "Detalles", value: "details" },
      ],
    };
  },
  methods: {
    navigate(id) {
      this.$router.push({ name: "customer", query: { id: id } });
    },
  },
  mounted() {
    this.$store.dispatch("get_inactive_customers").then((response) => {
      this.inactive_customers = response.data;
    });
  },
};
</script>