<template>
	<v-container fluid>
		<v-container>
			<v-row justify="center"><p class="text-h5">Pagos</p></v-row>
		</v-container>

		<v-row justify="center">
			<v-col>
				<v-card>
					<v-card-title>
						<v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
					</v-card-title>

					<v-data-table :items="payment_records" :headers="headers" sort-by="id" sort-desc :search="search">
						<template v-slot:item.payment_date="{ item }">
							{{ item.payment_date | formatDate }}
						</template>

						<template v-slot:item.payment_details="{ item }">
							<div class="d-flex justify-left" v-for="(payment, index) in item.payment_details" :key="index">
								{{ payment.channel_name }}
							</div>
						</template>

						<template v-slot:item.credits="{ item }">
							<div v-for="(payment, index) in item.payment_details" :key="index" class="d-flex justify-center">
								{{ payment.credits }}
							</div>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<vue-json-to-csv :json-data="export_data" :csv-title="'pagos'">
					<v-btn color="success" class="mr-6"> Exportar <i class="mdi mdi-export-variant"></i> </v-btn>
				</vue-json-to-csv>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import api from "../services/api";

export default {
	components: { VueJsonToCsv },
	data() {
		return {
			search: "",
			payment_records: [],
			headers: [
				{ text: "Nombre", value: "customer.name" },
				{ text: "Num. Cliente", value: "customer.customer_no" },
				{ text: "Subscripciones", value: "payment_details" },
				{ text: "Dispositivo", value: "device" },
				{ text: "Cantidad", value: "quantity" },
				{ text: "Créditos", value: "credits", align: "center" },
				{ text: "Fecha", value: "payment_date" },
				{ text: "Monto", value: "amount" },
				{ text: "Tipo", value: "payment_type" },
				{ text: "Comprobante", value: "voucher" },
				{ text: "Usuario", value: "user" },
			],
		};
	},
	computed: {
		export_data() {
			return this.payment_records.map((row) => {
				return {
					nombre: row.customer ? row.customer.name : "",
					subscripciones: row.payment_details.map((payment) => {
						return payment.channel_name;
					}),
					creditos: row.payment_details.map((payment) => {
						return payment.credits;
					}),
					fecha: row.payment_date,
					monto: row.amount,
					comprobante: row.voucher,
					usuario: row.user,
					tipo: row.payment_type,
				};
			});
		},
	},
	mounted() {
		api.get_all_payments().then((response) => {
			this.payment_records = response.data;
		});
	},
};
</script>

<style></style>
