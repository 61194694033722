<template>
	<v-form v-model="valid">
		<v-container>
			<v-sheet>
				<v-card>
					<v-card-title>Dispositivo</v-card-title>
					<v-card-text>
						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-select label="Tipo de dispositivo" :items="device_types" item-text="type" item-value="id" v-model="new_device.type" :rules="[required_rule]" />
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-text-field label="Serial" v-model="new_device.serial" />
							</v-col>
						</v-row>

						<v-row justify="center">
                            
							<v-col cols="8" align="center">
								<v-radio-group v-model="customer_selection" label="Cliente">
									<v-radio label="Nuevo" value="new"></v-radio>
									<v-radio label="Existente" value="existing"></v-radio>
								</v-radio-group>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-sheet>
		</v-container>

		<v-container  v-if="customer_selection === 'existing'">
			<v-sheet>
				<v-card>
                    <v-card-title>Cliente existente</v-card-title>
                    <v-card-text>
						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-autocomplete v-model="new_device.customer" :items="customers" label="Cliente">
									<template v-slot:item="data">
										<v-list-item-content v-text="data.item.text" class="text-subtitle-2"></v-list-item-content>
									</template>

									<template v-slot:selection="data">
										<div v-bind="data.attrs" :input-value="data.selected" class="text-subtitle-2">
											{{ data.item.text }}
										</div>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-card-text>
                </v-card>
			</v-sheet>
		</v-container>

        <v-container v-show="customer_selection === 'new'">
            <NewCustomerForm ref="new_customer_form" />
        </v-container>

		<v-container>
			<v-sheet>
				<v-card>
					<v-card-title>Pago</v-card-title>
					<v-card-text>
                        <v-row justify="center">
                            <v-col cols="8" align="center">
                                <v-text-field v-model="new_device.quantity" label="Cantidad"/>
                            </v-col>
                        </v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-text-field v-model="new_device.voucher" label="Comprobante" />
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-select label="Tipo de pago" :items="payment_types" v-model="new_device.paid_via" :rules="[required_rule]" />
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="new_device.payment_date" label="Fecha de pago" readonly v-bind="attrs" v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="new_device.payment_date" @input="menu = false" locale="es"></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-text-field
									label="Monto"
									v-model.number="new_device.amount"
									type="number"
									prefix="$"
									:hint="'$' + String(suggested_amount)"
									persistent-hint
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-sheet>
		</v-container>
		<v-container>
			<v-row justify="center">
				<v-col cols="8" align="center">
					<v-btn :disabled="!valid" color="info" @click="save_device()">Guardar</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>
<script>
import api from "../services/api";
import NewCustomerForm from "./NewCustomerForm.vue"
import moment from "moment"
export default {
    components: { NewCustomerForm },
	data() {
		return {            
            valid: false,
            new_customer: {},
			menu: false,
			payment_types: ["BANCO", "EFECTIVO", "PAYPAL", "CC/CARGO AUT", "ADMINISTRATIVO", "COMPRA CLICK", "SINPE", "REFERIDOS"],
			device_types: [],
			customer_selection: "",
			customer: {},
			new_device: {
				serial: "",
				customer: "",
                quantity: 1,
				type: "",
                paid_via: "",
				voucher: "",
				payment_date: moment().toISOString(true).split("T")[0],
				amount: 0,
				user: this.$store.getters.user.username,
			},
		};
	},
	computed: {
        length_rule() {
            return v => (v || '').length <= 0 || 'Requerido'
        },
        required_rule() {
            return v => v != "" || 'Requerido'
        },
		suggested_amount() {
			if (this.new_device.type != "") {
				const sell_price = this.device_types.find((device) => device.id === this.new_device.type).sell_price;
                return Number(sell_price) * Number(this.new_device.quantity)
			}
			return 0;
		},
		customers() {
			return this.$store.getters.customers.map((customer) => {
				return {
					value: customer.id,
					text: customer.name + " | " + customer.customer_no,
				};
			});
		},
	},
	methods: {
		get_device_types() {
			api.get_device_types().then((response) => (this.device_types = response.data));
		},
		save_device() {
			api.new_device(this.new_device, this.new_customer).then((response) => {
				if (response.status === 200) {
					this.$router.push("devices");
				}
			});
		},
	},
	mounted() {
        this.new_customer = this.$refs.new_customer_form.customer;
		this.get_device_types();
	},
};
</script>
